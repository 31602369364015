import ApiService from "@/core/services/ApiService";
import useSWRV, { IConfig } from "swrv";
import { IKey } from "swrv/dist/types";
import { computed, ref, watch } from "vue";

export interface CommonResponse<T extends any> {
  data: {
    data: T;
    rc: string;
    message: string;
    timestamp: string;
    current_page?: number;
    from?: number;
    last_page?: number;
    per_page?: number;
    to?: number;
    total?: number;
  };
}

export const SWRVfetcher = (url, params = {}) => {
  ApiService.setHeader();
  return ApiService.query(url, { params });
};

const serializeKey = (params: any) => {
  return JSON.stringify(params);
};

export const makeStringKey = (key: IKey) => {
  if (typeof key === "string") return key;
  if (typeof key !== "function") return serializeKey(key);
  const invokedKey = (key as any)();
  if (typeof invokedKey === "string") return invokedKey;
  return serializeKey(invokedKey);
};

export type useRequestOptions = IConfig & {
  useCache?: boolean;
};

type UrlFunction = () => string | null | undefined;
type Params = any | null;
type ParamsFunction = () => Params;

const invokeWhenNeeded = (maybeFunction: any) => {
  if (typeof maybeFunction !== "function") return maybeFunction;
  return invokeWhenNeeded(maybeFunction());
};

const useRequest = <T extends any>(
  url: string | UrlFunction,
  params: Params | ParamsFunction = null,
  options: useRequestOptions = {
    shouldRetryOnError: false,
  }
) => {
  // first loaded state
  const { useCache, ...useSWRVConfig } = options;
  const allResponse = useSWRV<T>(
    () =>
      invokeWhenNeeded(url) &&
      makeStringKey([invokeWhenNeeded(url), invokeWhenNeeded(params)]),
    () => {
      if (useCache) return null;
      return SWRVfetcher(
        invokeWhenNeeded(url),
        invokeWhenNeeded(params)
      ) as any;
    },
    {
      ...useSWRVConfig,
      revalidateOnFocus: false
    }
  );

  const isHasBeenLoaded = ref(false);
  watch(
    () => allResponse.isValidating.value,
    (newValue, oldValue) => {
      if (newValue !== oldValue) {
        isHasBeenLoaded.value = true;
      }
    }
  );

  const isEmpty = computed(() => isHasBeenLoaded.value && !allResponse.data);

  return { ...allResponse, isEmpty, isHasBeenLoaded };
};

export default useRequest;
