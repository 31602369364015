import { translate } from "@/core/helpers/translate";

const numberFormat = (number, decimals = 0, decPoint = ",", thousandSep = ".") => {
  // Strip all characters but numerical ones.
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
  const n = !isFinite(+number) ? 0 : +number
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = typeof thousandSep === 'undefined' ? ',' : thousandSep
  const dec = typeof decPoint === 'undefined' ? '.' : decPoint
  let s: string[] = [""];

  const toFixedFix = (n, prec) => {
    const k = 10 ** prec
    return '' + Math.round(n * k) / k
  }
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }

  const result = s.join(dec)
  return result
}

const formatRp = number => {
  return "Rp" + numberFormat(number);
}

const numberToWord = (a) => {
  const bilangan = [
    '',
    translate('One'),
    translate('Two'),
    translate('Three'),
    translate('Four'),
    translate('Five'),
    translate('Six'),
    translate('Seven'),
    translate('Eight'),
    translate('Nine'),
    translate('Ten'),
    translate('Eleven'),
    translate('Twelve'),
    translate('Thirteen'),
    translate('Fourteen'),
    translate('Fifteen'),
    translate('Sixteen'),
    translate('Seventeen'),
    translate('Eighteen'),
    translate('Nineteen'),
  ];
  bilangan[20] = translate('Twenty');
  bilangan[30] = translate('Thirty');
  bilangan[40] = translate('Forty');
  bilangan[50] = translate('Fifty');
  bilangan[60] = translate('Sixty');
  bilangan[70] = translate('Seventy');
  bilangan[80] = translate('Eighty');
  bilangan[90] = translate('Ninety');  

  let kalimat = "";

	// 1 - 20
	if(a < 20){
		
    kalimat = bilangan[a];
	}
	// 20 - 99
	else if(a < 100){
		const utama = a/10;
		const depan = parseInt(String(utama).substr(0,1));
		const belakang = a%10;
		
    kalimat = bilangan[Number(`${depan}0`)]+' '+bilangan[belakang];
	}
	// 100 - 199
	else if(a < 200){
		
    kalimat = `${translate('OneHundred')} `+ numberToWord(a - 100);
	}
	// 200 - 999
	else if(a < 1000){
		const utama = a/100;
		const depan = parseInt(String(utama).substr(0,1));
		const belakang = a%100;
		
    kalimat = bilangan[depan] + ` ${translate('Hundred')} `+ numberToWord(belakang);
	}
	// 1,000 - 1,999
	else if(a < 2000){
		
    kalimat = `${translate('OneThousand')} `+ numberToWord(a - 1000);
	}
	// 2,000 - 9,999
	else if(a < 10000){
		const utama = a/1000;
		const depan = parseInt(String(utama).substr(0,1));
		const belakang = a%1000;
		
    kalimat = bilangan[depan] + ` ${translate('Thousand')} `+ numberToWord(belakang);
	}
	// 10,000 - 99,999
	else if(a < 100000){
		const utama = a/100;
		const depan = parseInt(String(utama).substr(0,2));
		const belakang = a%1000;
		
    kalimat = numberToWord(depan) + ` ${translate('Thousand')} `+ numberToWord(belakang);
	}
	// 100,000 - 999,999
	else if(a < 1000000){
		const utama = a/1000;
		const depan = parseInt(String(utama).substr(0,3));
		const belakang = a%1000;
		
    kalimat = numberToWord(depan) + ` ${translate('Thousand')} `+ numberToWord(belakang);
	}
	// 1,000,000 - 	99,999,999
	else if(a < 100000000){
		const utama = a/1000000;
		const depan = parseInt(String(utama).substr(0,4));
		const belakang = a%1000000;
		
    kalimat = numberToWord(depan) + ` ${translate('Million')} `+ numberToWord(belakang);
	}
	else if(a < 1000000000){
		const utama = a/1000000;
		const depan = parseInt(String(utama).substr(0,4));
		const belakang = a%1000000;
		
    kalimat = numberToWord(depan) + ` ${translate('Million')} `+ numberToWord(belakang);
	}
	else if(a < 10000000000){
		const utama = a/1000000000;
		const depan = parseInt(String(utama).substr(0,1));
		const belakang = a%1000000000;
		
    kalimat = numberToWord(depan) + ` ${translate('Billion')} `+ numberToWord(belakang);
	}
	else if(a < 100000000000){
		const utama = a/1000000000;
		const depan = parseInt(String(utama).substr(0,2));
		const belakang = a%1000000000;
		
    kalimat = numberToWord(depan) + ` ${translate('Billion')} `+ numberToWord(belakang);
	}
	else if(a < 1000000000000){
		const utama = a/1000000000;
		const depan = parseInt(String(utama).substr(0,3));
		const belakang = a%1000000000;
		
    kalimat = numberToWord(depan) + ` ${translate('Billion')} `+ numberToWord(belakang);
	}
	else if(a < 10000000000000){
		const utama = a/10000000000;
		const depan = parseInt(String(utama).substr(0,1));
		const belakang = a%10000000000;
		
    kalimat = numberToWord(depan) + ` ${translate('Hundred')} `+ numberToWord(belakang);
	}
	else if(a < 100000000000000){
		const utama = a/1000000000000;
		const depan = parseInt(String(utama).substr(0,2));
		const belakang = a%1000000000000;
		
    kalimat = numberToWord(depan) + ` ${translate('Trillion')} `+ numberToWord(belakang);
	}

	else if(a < 1000000000000000){
		const utama = a/1000000000000;
		const depan = parseInt(String(utama).substr(0,3));
		const belakang = a%1000000000000;
		
    kalimat = numberToWord(depan) + ` ${translate('Trillion')} `+ numberToWord(belakang);
	}

  else if(a < 10000000000000000){
		const utama = a/1000000000000000;
		const depan = parseInt(String(utama).substr(0,1));
		const belakang = a%1000000000000000;
		
    kalimat = numberToWord(depan) + ` ${translate('Quadrillion')} `+ numberToWord(belakang);
	}

	const pisah = kalimat.split(' ');
	const full = [] as Array<any>;
	for(let i=0;i<pisah.length;i++){
	 if(pisah[i] != ""){full.push(pisah[i]);}
	}
	return full.join(' ');
}

export { numberFormat, formatRp, numberToWord }