
import { defineComponent, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { numberFormat } from "@/core/helpers/currency-helper";
import useInvocationDetail from "@/core/services/compositions/invocation/useInvocationDetail";
import { ElNotification } from 'element-plus'
import { checkPermission } from '@/router/authentication'

export default defineComponent({
  setup() {
    // init
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const invocationId = route.params.id;

    const { data, isValidating, error } = useInvocationDetail(invocationId);

    watch(error, (error) => {
      if (error.response.status == 403) {
        router.push({ name: "403" });

        return;
      }
      ElNotification({
        title: error.response.data.rc,
        message: error.response.data.message,
        type: 'error',
      })
    });

    const gotoEdit = (data) => {
      router.push({ name: "app.invocation-edit", params: { id: invocationId } });
    };

    return {
      invocationId,
      data,
      gotoEdit,
      numberFormat,
      checkPermission
    };
  },
});
